/* eslint-disable */
import axios from 'axios'
// import Vue from 'vue'

var session = axios.create({
  baseURL: 'https://backend-stg.smokapp.es',

  // baseURL:
  //   process.env.NODE_ENV === 'development'
  //     ? 'https://backend-stg.smokapp.es' //dev
  //     : 'https://backend.smokapp.com', //pro

  withCredentials: true,
  headers: {
    Authorization: 'Basic c21va2FwcEBzdGcuc21va2FwcC5lczpwYXRhbGllYnJl',
    'Access-Control-Allow-Origin': '*'
  }
})

export default session
